import { FC, ReactNode, useContext, useEffect } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { useUser, userStore } from '../../../../utils'
import { MOBILE_ROUTES } from '../../../utils'
import { InactiveView } from '../../../views'
import { Header, Nav } from '../..'
import { StyledAppLayout } from '.'

type Props = {
  children: ReactNode
}

const AppLayout: FC<Props> = ({ children }) => {
  const history = useHistory()
  const location = useLocation()
  const { loaded, user } = useContext(userStore)

  useEffect(() => {
    if (loaded && !user?.id) {
      history.push(MOBILE_ROUTES.splash)
    }
  }, [loaded, user])

  if (!user?.id) {
    return null
  }

  if (
    user.stripeSubscriptionStatus !== 'Active' &&
    !location.pathname.includes('/academy') &&
    !location.pathname.includes('/community') &&
    !location.pathname.includes('/account')
  ) {
    return <InactiveView />
  }

  if (
    !!user &&
    !user?.aiOnboarding?.occupation &&
    !location.pathname.includes('/academy') &&
    !location.pathname.includes('/community') &&
    !location.pathname.includes('/account') &&
    !(location?.state as any)?.redirect
  ) {
    return (
      <Redirect
        to={{
          pathname: MOBILE_ROUTES.onboarding,
          state: Object.fromEntries(new URLSearchParams(location.search))?.redirect
        }}
      />
    )
  }

  return (
    <StyledAppLayout>
      <Header />
      <div className="layout-root">{children}</div>
      <Nav />
    </StyledAppLayout>
  )
}

export default AppLayout
