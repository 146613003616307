import { Capacitor } from '@capacitor/core'

export const getServerURL = () => {
  const isDev = process.env.NODE_ENV === 'development'

  if (!isDev) {
    return process.env.REACT_APP_SERVER_URL!.replace('/graphql', '')
  }

  if (Capacitor.isNativePlatform()) {
    return 'http://192.168.2.14:8080'
  }

  return 'http://localhost:8080'
}
